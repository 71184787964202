/* Variables */
$black: #010000;
$white: #ffffff;
$lightGrey: #e6e6e6;
$darkGrey: #333;
$red: #ff1755;

/* Mixins */
@mixin translateY($e) {
    -webkit-transform: translateY($e);
    -moz-transform: translateY($e);
    transform: translateY($e);   
}

@mixin transition($e) {
    -webkit-transition: $e;
    -moz-transition: $e;
    transition: $e;   
}

body {
    font-size: 18px;
    color: $black;
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    font-style: normal;
}

a {
    @include transition(.4s ease-in-out);
    cursor: pointer;

    &:hover {
        text-decoration: none;
        color: red;
        @include transition(.4s ease-in-out);
    }
}

.container-fluid {
    line-height: 24px;
}

